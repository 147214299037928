@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.footer {
  margin-top: 80px;
  background: #4ec0de;

  .container {
    padding: 40px 24px 32px 24px;
    margin: 0 auto;
    max-width: 1200px;
    @include media-mo {
      // background: red;
      padding: 20px 32px 20px 32px;
    }
  }

  .links {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-small;
    color: white;
    .links_right {
      display: flex;
      width: 70%;
      justify-content: space-between;
    }
    .link {
    }
  }
  
  .copyright {
    margin-top: 50px;
    color: #ffffff;
    font-size: $font-size-small;
  }
}
