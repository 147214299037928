@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.header {
    background-color: white;
  }
  
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    margin: 0 auto;
  
    @media (min-width: 1024px) {
      padding-left: 3rem;
      padding-right: 1rem;
    }
  }
  
  .logoContainer {
    display: flex;
    flex: 1;
  }
  
  .logoLink {
    margin: -0.375rem;
    padding: 0.375rem;
    display: flex;
  }
  
  .srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .logoImage {
    height: 2.5rem;
    width: auto;
  }
  
  .logoText {
    margin-left: 0.5rem;
    font-size: 1.875rem;
    font-weight: 900;
  }
  
  .menuButtonContainer {
    display: flex;
  }
  
  .menuButton {
    margin: -0.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    padding: 0.625rem;
    color: #374151;
  }
  
  .menuIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .dialogOverlay {
    position: fixed;
    inset: 0;
    z-index: 10;
  }
  
  .dialogPanel {
    position: fixed;
    inset-y: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    padding: 1.5rem;
    overflow-y: auto;
    background-color: white;
  
    @media (min-width: 640px) {
      max-width: 24rem;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }
  }
  
  .dialogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .dialogLogoLink {
    margin: -0.375rem;
    padding: 0.375rem;
    display: flex;
  }
  
  .dialogLogoImage {
    height: 2.5rem;
    width: auto;
  }
  
  .dialogLogoText {
    margin-left: 0.5rem;
    font-size: 1.875rem;
    font-weight: 900;
  }
  
  .closeButton {
    margin: -0.625rem;
    border-radius: 0.375rem;
    padding: 0.625rem;
    color: #374151;
  }
  
  .closeIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .menuContent {
    margin-top: 1.5rem;
    overflow: hidden;
  }
  
  .menuList {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
    divide-y: 1px solid rgba(209, 213, 219, 0.1);
  }
  
  .menuItems {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    space-y: 0.5rem;
  }
  
  .menuItem {
    display: block;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75rem;
    color: #111827;
    border-radius: 0.375rem;
  
    &:hover {
      background-color: #f3f4f6;
    }
  }
  
  .badge {
    margin-left: 1rem;
    display: inline-flex;
    align-items: center;
    gap: 0.375rem;
    border-radius: 9999px;
    background-color: #fce7f3;
    padding: 0.125rem 0.375rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: #be185d;
  }
  
  .badgeIcon {
    height: 0.375rem;
    width: 0.375rem;
    fill: #ec4899;
  }
  
  .faqList {
    // margin-top: 2.5rem;
    space-y: 1.5rem;
    divide-y: 1px solid #e5e7eb;
  }
  
  .faqItem {
    // padding-top: 1.5rem;
  }
  
  .faqButton {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    color: #111827;
  }
  
  .faqTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75rem;
  }
  
//   .faqIcon {
//     margin-left: 1.5rem;
//     display: flex;
//     height: 1.75rem;
//     align-items: center;
//   }
  
  .plusIcon {
    height: 1.5rem;
    width: 1.5rem;
    display: block;
  }
  
  .minusIcon {
    height: 1.5rem;
    width: 1.5rem;
    display: none;
  
    [data-open] & {
      display: block;
    }
  }
  
  .faqPanel {
    margin-top: 1.5rem;
    padding-left: 1.5rem;
    display: grid;
    gap: 0;
  }
  
  .faqLink {
    font-size: 1rem;
    line-height: 1.75rem;
    color: #4b5563;
  }
  
  .authSection {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .authLink {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    display: block;
    border-radius: 0.375rem;
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75rem;
    color: #111827;
  
    &:hover {
      background-color: #f3f4f6;
    }
  }


  .dialog {
    position: fixed;
    inset: 0;
    z-index: 50;
    overflow-y: auto;
  }
  
  .dialogOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .dialogPanelWrapper {
    position: fixed;
    inset: 0;
    overflow-y: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 50;
  }
  
  .dialogPanel {
    position: relative;
    width: 100%;
    max-width: 24rem;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  // Transition styles
  .overlayEnter {
    transition: opacity 0.3s ease-out;
  }
  
  .overlayEnterFrom {
    opacity: 0;
  }
  
  .overlayEnterTo {
    opacity: 1;
  }
  
  .overlayLeave {
    transition: opacity 0.2s ease-in;
  }
  
  .overlayLeaveFrom {
    opacity: 1;
  }
  
  .overlayLeaveTo {
    opacity: 0;
  }
  
  .panelEnter {
    transition: transform 0.3s ease-out;
  }
  
  .panelEnterFrom {
    transform: translateX(100%);
  }
  
  .panelEnterTo {
    transform: translateX(0);
  }
  
  .panelLeave {
    transition: transform 0.2s ease-in;
  }
  
  .panelLeaveFrom {
    transform: translateX(0);
  }
  
  .panelLeaveTo {
    transform: translateX(100%);
  }
  

  .userInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
  }
  
  .userAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  
  .userName {
    font-size: 14px;
    font-weight: 500;
  }