@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.form {
    margin: 0 auto;
    max-width: 80rem;
    width: 50%;
  }
  
  .formContent {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  
  .section {
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(17, 24, 39, 0.1);
  }
  
  .sectionTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75rem;
    color: #111827;
  }
  
  .inputGrid {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  
    @media (min-width: 640px) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
  
  .fullWidth {
    grid-column: 1 / -1;
  }
  
  .label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #111827;
  }
  
  .inputWrapper {
    margin-top: 0.5rem;
  }
  
  .textarea {
    display: block;
    width: 100%;
    border-radius: 0.375rem;
    border: 0;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #111827;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px #d1d5db;
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #6366f1, inset 0 0 0 1px #6366f1;
    }
  
    &::placeholder {
      color: #9ca3af;
    }
  }
  
  .photoUpload {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    gap: 0.75rem;
  }
  
  .userIcon {
    width: 3rem;
    height: 3rem;
    color: #d1d5db;
  }
  
  .changeButton {
    border-radius: 0.375rem;
    background-color: white;
    padding: 0.375rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), inset 0 0 0 1px #d1d5db;
  
    &:hover {
      background-color: #f9fafb;
    }
  }
  
  .coverPhotoUpload {
    display: flex;
    justify-content: center;
    padding: 2.5rem 1.5rem;
    margin-top: 0.5rem;
    border: 2px dashed #d1d5db;
    border-radius: 0.5rem;
  }
  
  .uploadContent {
    text-align: center;
  }
  
  .photoIcon {
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
    color: #d1d5db;
  }
  
  .uploadText {
    display: flex;
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4b5563;
  }
  
  .uploadLabel {
    font-weight: 600;
    color: #4f46e5;
    cursor: pointer;
  
    &:hover {
      color: #4338ca;
    }
  
    &:focus-within {
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-shadow: 0 0 0 2px #c7d2fe;
    }
  }
  
  .dragDropText {
    padding-left: 0.25rem;
  }
  
  .fileTypeText {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #6b7280;
  }
  
  .srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .formActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.5rem;
    gap: 1.5rem;
  }
  
  .cancelButton {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #111827;
  }
  
  .saveButton {
    border-radius: 0.375rem;
    background-color: #4f46e5;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  
    &:hover {
      background-color: #4338ca;
    }
  
    &:focus-visible {
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-shadow: 0 0 0 2px #c7d2fe;
    }
  }