@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal_container {
    position: absolute;
    padding: 20px;
    min-width: 400px;
    min-height: 200px;
    border-radius: 12px;
    background: white;
  }
}
