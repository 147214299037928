@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.postItem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  isolation: isolate;
}

.content {
  @media (min-width: 1024px) {
    display: flex;
    gap: 30px;
  }
}

.postImageLink {
  position: relative;
  aspect-ratio: 16 / 9;

  @media (min-width: 640px) {
    aspect-ratio: 2 / 1;
  }

  @media (min-width: 1024px) {
    aspect-ratio: 1 / 1;
    width: 16rem;
    flex-shrink: 0;
  }
}

.emptyImage {
  background-color: #e0e0e0; // 회색 배경
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // 부모 요소의 높이를 채우도록 설정
  width: 100%; // 부모 요소의 너비를 채우도록 설정

  span {
    color: #999;
    font-size: 14px;
  }
}

.postImage {

  @media (min-width: 640px) {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-color: #f9fafb;
  }
}

.postImageOverlay {
  position: absolute;
  inset: 0;
}

.postContent {
  flex: 1;
}

.postDate {
  color: #6b7280;
}

.postCategory {
  position: relative;
  z-index: 10;
  border-radius: 9999px;
  background-color: #f9fafb;
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  color: #4b5563;

  &:hover {
    background-color: #f3f4f6;
  }
}

.postTitle {
  margin-top: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #111827;

  .postTitleLink:hover & {
    color: #4b5563;
  }
}

.userName {
  position: absolute;
  right: 0;
  top: 0;
}

.postDescription {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #4b5563;
}

.postFooter {
  display: flex;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid rgba(17, 24, 39, 0.05);
}

.authorName {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.authorInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.authorNameLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.commentIcon,
.viewIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.viewCount {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.viewCountText {
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
