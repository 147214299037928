@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.container {
  width: 100%;
  padding: 0.5rem 3rem;
  margin: 0 auto;
  background-color: white;
  max-width: 80rem;

  @media (min-width: 640px) {
    padding: 3rem 0;
  }
}

.searchContainer {
  display: flex;
}

.searchInputWrapper {
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 30rem;
  position: relative;
}

.searchInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  color: #111827;
  border-bottom: 1px solid #d1d5db;
  font-size: 0.875rem;
  line-height: 1.5rem;

  &::placeholder {
    color: #9ca3af;
  }

  &:focus {
    outline: none;
    border-color: #4f46e5;
  }
}

.searchIcon {
  position: absolute;
  right: 0;
  bottom: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
}

.writeButtonContainer {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;

  @media (min-width: 1280px) {
    width: 37%;
  }

  @media (min-width: 640px) {
    width: 60%;
  }
}

.writeButton {
  float: right;
  border-radius: 0.375rem;
  background-color: #4f46e5;
  padding: 0.625rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #4338ca;
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 0 2px #c7d2fe;
  }
}

.postsContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding: 1rem 1.5rem 5rem;

  @media (min-width: 1280px) {
    width: 45%;
  }

  @media (min-width: 640px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.postsWrapper {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    max-width: 56rem;
  }
}

.postsList {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  @media (min-width: 1024px) {
    margin-top: 5rem;
    gap: 5rem;
  }
}

.postMetadata {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.75rem;
}

.postTitleLink {
  position: relative;
  max-width: 36rem;
}


