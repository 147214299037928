@import "assets/style/function.scss"; @import "assets/style/variable.scss";
.postItem {
  position: relative;
  gap: 2rem;
  isolation: isolate;
}

.imageGallery {
  display: flex;
  gap: 1rem;
}

.imgflex {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.imageWrapper {
  position: relative;
  aspect-ratio: 16 / 9;
  width: 16rem;
  flex-shrink: 0;

  @media (min-width: 640px) {
    aspect-ratio: 2 / 1;
  }

  @media (min-width: 1024px) {
    aspect-ratio: 1 / 1;
  }
}

.image {
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: #f9fafb;
}

.imageOverlay {
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  box-shadow: inset 0 0 0 1px rgba(17, 24, 39, 0.1);
}

.postContent {
  margin-top: 3rem;
}

.postMeta {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  gap: 1rem;
}

.postDate {
  color: #6b7280;
}

.postCategory {
  position: relative;
  z-index: 10;
  border-radius: 9999px;
  background-color: #f9fafb;
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  color: #4b5563;

  &:hover {
    background-color: #f3f4f6;
  }
}

.heartButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.heartIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.heartText {
  font-size: 1rem;
}

.postTitle {
  position: relative;
  max-width: 36rem;

  h3 {
    margin-top: 0.75rem;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #111827;

    &:hover {
      color: #4b5563;
    }
  }
}

.postTitleLink {
  position: absolute;
  inset: 0;
}

.postState, .postDescription {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #4b5563;
}

.postFooter {
  display: flex;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid rgba(17, 24, 39, 0.05);
}

.authorInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.authorName {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.authorLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.authorLinkOverlay {
  position: absolute;
  inset: 0;
}

.commentIcon, .viewIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.swiper {
  width: 100%;
}

.viewCount {
  display: flex;
  align-items: center;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.emptySlide {
  background-color: #e0e0e0; // 회색 배경
  height: 200px; // 원하는 높이로 조정
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '이미지 없음';
    color: #999;
    font-size: 14px;
  }
}